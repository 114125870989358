<!--
 * @Description:维护管理 待处理工单 waitDisposeWork
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-20 14:14:20
 * @LastEditors: zhoucheng
-->
<template>
  <div class='waitDisposeWork-mainBody'>
    <van-nav-bar title="待处理工单"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickBack"
                 @click-right="handleClickTopSelect">
      <template #right>
        <svg t="1620695646196"
             class="icon"
             viewBox="0 0 1024 1024"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             p-id="2524"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             width="18"
             height="18">
          <path d="M965.34016 114.78528c-14.0032-30.85312-44.82048-50.78528-78.50496-50.78528l-744.22272-0.01024a82.9184 82.9184 0 0 0-76.4928 50.70336c-13.79328 32.0768-7.40352 68.10112 16.5888 92.11904l2.64192 3.16928 274.79552 220.2368a23.76192 23.76192 0 0 1 9.26208 18.38592v377.83552a84.13184 84.13184 0 0 0 44.63104 75.01824l118.96832 66.27328c13.12256 6.85056 26.64448 10.17856 41.32352 10.17856h0.06656c23.30624 0 45.00992-8.9856 61.11744-25.30304 15.85664-16.06144 24.4224-37.48352 24.12544-60.39552V450.95424a24.36608 24.36608 0 0 1 8.5248-18.304l278.7072-222.83264 3.29728-3.22048a83.57376 83.57376 0 0 0 15.17056-91.81184z m-60.0576 48.20992l-0.38912 0.52736-275.25632 221.55264c-20.10112 16.20992-31.62112 40.4736-31.62112 66.57024v440.56064c0.0256 8.29952-4.352 16.1536-11.84256 20.72576a26.66496 26.66496 0 0 1-11.77088 2.75456 23.92064 23.92064 0 0 1-11.52-2.85696l-118.89664-65.05472c-7.30112-3.60448-11.84256-11.56608-11.84256-20.78208V449.2032c0-25.81504-11.74016-50.048-32.19968-66.48832L134.13376 170.53184l-9.32352-7.6288c-5.15584-7.28064-5.74464-16.68608-1.68448-24.2176 3.72224-8.26368 11.52-13.19424 20.85888-13.19424H886.784a23.26016 23.26016 0 0 1 20.76672 13.04576 23.5008 23.5008 0 0 1-2.26816 24.45824z"
                p-id="2525"
                fill="#666666"></path>
          <path d="M748.20608 805.69344c-16.34816-0.4352-29.50656-14.18752-29.18912-30.67904 0-17.03424 13.09696-30.81728 29.18912-30.81728h195.4048c16.09728 0 29.18912 13.77792 29.18912 30.71488a29.6192 29.6192 0 0 1-8.064 21.4272 29.50656 29.50656 0 0 1-20.736 9.33888l-0.38912 0.01536h-195.4048zM748.20608 667.91936c-16.32256-0.42496-29.47072-14.16704-29.18912-30.6176 0-17.05984 13.09696-30.86336 29.18912-30.86336h195.4048c16.09728 0 29.18912 13.80352 29.18912 30.77632a29.31712 29.31712 0 0 1-7.79776 21.29408 29.22496 29.22496 0 0 1-20.44416 9.39008l-0.9472 0.0256h-195.4048zM748.20608 530.1504c-16.32256-0.42496-29.47072-14.16704-29.18912-30.62272 0-17.024 13.09696-30.7968 29.18912-30.7968h195.4048c16.09728 0 29.18912 13.7728 29.18912 30.70976 0.3072 8.04352-2.46272 15.53408-7.79776 21.29408s-12.5952 9.09312-20.44416 9.3952l-0.98816 0.02048h-195.36384z"
                p-id="2526"
                fill="#666666"></path>
        </svg>
        <span style="margin-left:10px">筛选</span>
      </template>
    </van-nav-bar>
    <div class="order-list">
      <!-- 待处理工单列表 -->
      <van-list v-model="loading"
                :finished="finished"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                finished-text="没有更多了"
                @load="queryWaitDisposeWork">
        <van-row class="contentBodyItem"
                 v-for="item in waitDisposeWorkList"
                 :key="item.workTicketSequence"
                 @click="handleClickWaitDisposeWork(item)">
          <van-row class="lineOne">
            <van-row class="lineOne-left"></van-row>
            <van-row class="lineOne-mid"> {{item.workTicketSequence}} </van-row>
            <van-row class="lineOne-right"
                     style="background:#F69624"
                     v-show="item.deviceFaultTypeCode ===1">故障</van-row>
            <van-row class="lineOne-right"
                     style="background:#F26B6A"
                     v-show="item.deviceFaultTypeCode ===2">损坏</van-row>
            <van-row class="lineOne-right"
                     style="background:#19A9FC"
                     v-show="item.deviceFaultTypeCode ===3">其他</van-row>
          </van-row>
          <van-row class="lineTwo">
            <van-row class="lineTwoContent">
              <van-row class="lineTwo-title">问题描述</van-row>
              <van-row class="lineTwo-content">{{item.deviceFaultDesc}} </van-row>
            </van-row>
          </van-row>
          <van-row class="lineThree">
            <span class="lineThree-title">维护单位</span>
            <span class="lineThree-content"> {{item.maintenanceList? item.maintenanceList.maintenanceName:''}}</span>
          </van-row>
          <van-row class="lineThree">
            <span class="lineThree-title">联系电话</span>
            <span class="lineThree-content"> {{item.maintenanceList? item.maintenanceList.contactPhoneNumber:''}}</span>
          </van-row>
          <van-row class="lineThree">
            <span class="lineThree-title">联系人员</span>
            <span class="lineThree-content"> {{item.maintenanceList? item.maintenanceList.contact:''}}</span>
          </van-row>
          <!-- <van-row class="lineThree">
            <span class="lineThree-title">创建时间</span>
            <span class="lineThree-content"> {{item? item.ticketCreateTime:''}}</span>
          </van-row> -->
          <van-row class="lineThree"
                   style="padding-bottom:10px">
            <span class="lineThree-title">确认时间</span>
            <span class="lineThree-content"> {{item? item.ticketConfirmTime:''}}</span>
          </van-row>
        </van-row>
      </van-list>
    </div>
    <!-- 筛选弹出层 -->
    <van-popup v-model="show"
               position="top"
               :style="{ height: '45%' }">
      <div class="pop-box">
        <van-nav-bar title="待处理工单" />
        <div class="popTitle">时间</div>
        <div class="dateArea">
          <div class="dateItem"
               @click="startDateShow = true">{{popStartTime ||'开始时间'}}</div>
          <div class="dateLine"></div>
          <div class="dateItem"
               @click="endDateShow = true">{{popEndTime || '结束时间'}}</div>
        </div>
        <div class="popTitle">问题类型</div>
        <div class="status-box">
          <div :class="JSON.stringify(searchForm.deviceFaultTypeCodeList) === JSON.stringify([1,2,3]) ? 'statusActive':'status-item'"
               @click="searchForm.deviceFaultTypeCodeList = [1,2,3]">全部</div>
          <div :class="JSON.stringify(searchForm.deviceFaultTypeCodeList)=== JSON.stringify([1]) ? 'statusActive':'status-item'"
               @click="searchForm.deviceFaultTypeCodeList = [1]">故障</div>
          <div :class="JSON.stringify(searchForm.deviceFaultTypeCodeList) ===  JSON.stringify([2])? 'statusActive':'status-item'"
               @click="searchForm.deviceFaultTypeCodeList = [2]">损坏</div>
        </div>
        <div class="status-box">
          <div :class="JSON.stringify(searchForm.deviceFaultTypeCodeList)===  JSON.stringify([3]) ? 'statusActive':'status-item'"
               @click="searchForm.deviceFaultTypeCodeList = [3]">其他</div>
        </div>
        <div class="but-box">
          <div class="resetQuery"
               @click="resetQuery">重 置</div>
          <div class="sousuo"
               @click="queryWaitDisposeWork(1)">搜 索</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="startDateShow"
               position="bottom"
               :style="{ height: '40%' }">
      <van-datetime-picker v-model="startTime"
                           type="date"
                           title="选择年月日"
                           @confirm="searchForm.startTime = $fmtDate(startTime,'yyyy-MM-dd'); startDateShow = false"
                           @cancel="startDateShow = false" />
    </van-popup>
    <van-popup v-model="endDateShow"
               position="bottom"
               :style="{ height: '40%' }">
      <van-datetime-picker v-model="endTime"
                           type="date"
                           title="选择年月日"
                           @confirm="searchForm.endTime = $fmtDate(endTime,'yyyy-MM-dd');endDateShow = false"
                           @cancel="endDateShow = false" />
    </van-popup>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      waitDisposeWorkList: [], // 待确认工单列表
      deviceTypeList: [], // 设备类型
      typeShow: 0, // 问题类型
      loading: false,
      finished: false,
      error: false,
      // 筛选弹窗
      show: false,
      startDateShow: false,
      endDateShow: false,
      startTime: new Date(),
      endTime: new Date(),
      // 当前日期
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      todayTime: this.$fmtDate(new Date(), 'yyyy-MM-dd'),
      // 查询条件
      searchForm: {
        deviceTypeCodeList: [],
        ticketStatusCodeList: [2],
        deviceFaultTypeCodeList: [1, 2, 3],
        startTime: '',
        endTime: '',
        pageNum: 0,
        pageSize: 2
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {
    popStartTime () {
      if (this.searchForm.startTime) {
        return this.$fmtDate(new Date(this.searchForm.startTime), 'yyyy-MM-dd')
      }
      return ''
    },
    popEndTime () {
      if (this.searchForm.endTime) {
        return this.$fmtDate(new Date(this.searchForm.endTime), 'yyyy-MM-dd')
      }
      return ''
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.queryDeviceType()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 点击待处理工单每项
    handleClickWaitDisposeWork (item) {
      this.$router.push({
        name: 'waitDisposeWorkDetails',
        query: {
          deviceType: item.deviceType,
          deviceTypeCode: item.deviceTypeCode,
          deviceId: item.deviceId,
          maintenanceId: item.maintenanceId,
          workTicketSequence: item.workTicketSequence
        }
      })
    },
    // 顶部筛选按钮
    handleClickTopSelect () {
      this.searchForm = {
        deviceTypeCodeList: [],
        ticketStatusCodeList: [2],
        deviceFaultTypeCodeList: [1, 2, 3],
        startTime: '',
        endTime: '',
        pageNum: 0,
        pageSize: 2
      }
      this.show = true
    },
    // 查询待处理工单列表
    queryWaitDisposeWork (type = '') {
      this.show = false
      if (type) {
        this.searchForm.pageNum = 1
        this.finished = false
      } else {
        this.searchForm.pageNum++
      }
      this.searchForm.deviceTypeCodeList = this.deviceTypeList
      this.$waitDisposeWork.queryWorkTicketList(this.searchForm).then(res => {
        if (type) {
          this.waitDisposeWorkList = res.resultEntity.list
          this.waitDisposeWorkList.forEach((item, index) => {
            this.queryOperational(item.maintenanceId, index)
          })
        } else {
          this.waitDisposeWorkList = [...this.waitDisposeWorkList, ...res.resultEntity.list]
          this.waitDisposeWorkList.forEach((item, index) => {
            this.queryOperational(item.maintenanceId, index)
          })
        }
        if (this.waitDisposeWorkList.length >= res.resultEntity.total) {
          this.finished = true
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.error = true
      })
    },
    // 重置按钮
    resetQuery () {
      this.searchForm = {
        deviceTypeCodeList: [],
        ticketStatusCodeList: [2],
        deviceFaultTypeCodeList: [1, 2, 3],
        startTime: '',
        endTime: '',
        pageNum: 0,
        pageSize: 2
      }
    },
    // 查询运维单位
    queryOperational (val, index) {
      const info = {
        maintenanceId: val
      }
      this.$waitDisposeWork.queryMaintenance(info).then(res => {
        this.waitDisposeWorkList[index].maintenanceList = res.resultEntity
        this.$forceUpdate()
      })
    },
    // 设备类型查询
    queryDeviceType () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '9A5949F452B54BD9A96B9E978157CD1B'
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        const deviceTypeList = []
        res.resultEntity.forEach(item => {
          deviceTypeList.push(item.code)
        })
        this.deviceTypeList = deviceTypeList
      })
    },
    // 顶部返回按钮
    handleClickBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.waitDisposeWork-mainBody {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .order-list {
    height: calc(100% - @navHeight);
    overflow: auto;
  }
  .contentBodyItem {
    width: 93.6%;
    height: 250px;
    margin-left: 3.2%;
    margin-top: 10px;
    margin-bottom: 20px;
    .lineOne {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      position: relative;
      background: #ffffff;
      align-items: center;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-bottom: 1px solid #e7e8e8;
      .lineOne-left {
        width: 4px;
        height: 20px;
        background: #19a9fc;
        margin-left: 3.2%;
      }
      .lineOne-mid {
        font-family: PingFang-SC-Medium;
        font-size: 17px;
        color: #333333;
        margin-left: 2%;
      }
      .lineOne-right {
        width: 30px;
        height: 17px;
        line-height: 17px;
        position: absolute;
        float: right;
        right: 10%;
        font-family: PingFang-SC-Regular;
        font-size: 11px;
        color: #ffffff;
        text-align: center;
      }
    }
    .lineTwo {
      width: 100%;
      height: 87px;
      background: #ffffff;
      padding-top: 12px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      .lineTwoContent {
        background: #f6faff;
        width: 93.6%;
        height: 90px;
        margin-left: 3.2%;
        padding-top: 5px;
        .lineTwo-title {
          font-family: PingFang-SC-Regular;
          color: #909090;
          font-size: 15px;
          margin-left: 3.5%;
        }
        .lineTwo-content {
          font-family: PingFang-SC-Regular;
          color: #333333;
          font-size: 15px;
          margin-left: 3.5%;
        }
      }
    }
    .lineThree {
      width: 100%;
      background: #ffffff;
      padding-top: 5px;
      font-family: PingFang-SC-Regular;
      .lineThree-title {
        width: 93.6%;
        margin-left: 3.2%;
        color: #909090;
      }
      .lineThree-content {
        width: 93.6%;
        margin-left: 3.2%;
        color: #333333;
      }
    }
  }
  .pop-box {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    .popTitle {
      margin-top: 5px;
    }
    .dateArea {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dateItem {
        width: 40%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border: #dfe3e2 1px solid;
        color: #c3c4c8;
      }
      .dateLine {
        width: 8%;
        height: 1px;
        background: #666666;
      }
    }
    .status-box {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .status-item {
        width: 30%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #dfe3e2;
        font-size: 15px;
        font-weight: Regular;
        color: #1f4255;
      }
      .statusActive {
        width: 30%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #19a9fc;
        font-size: 15px;
        font-weight: Regular;
        color: #1f4255;
        background: #f3fbff;
      }
    }
    .but-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 10px;
      .resetQuery {
        width: 35%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #dfe3e2;
        border-radius: 6px;
      }
      .sousuo {
        width: 35%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #dfe3e2;
        border-radius: 6px;
        background: #19a9fc;
        color: #ffffff;
      }
    }
  }
}
</style>
